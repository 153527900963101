import React from 'react'

import item1 from "assets/images/item-1.png"
import visionMission from "assets/images/vision-mission.png"

import { about, people } from 'utils/constants/data'
import Newsletter from 'components/layout/Newsletter'

function About() {
    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>Leveraging real estate to the next level</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container space-y-4'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        About us
                    </p>
                    <div className='text-app-blue-3 text-center text-sm sm:text-base max-w-2xl mx-auto'>{about.description}</div>
                </div>
            </section>

            <section className='bg-app-grey'>
                <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-center'>
                    <img className='app-animation-translate-right-start' src={visionMission} alt="vision and mission" />
                    <div className='space-y-4 app-animation-translate-start'>
                        <div>
                            <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Vision</p>
                            <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </div>
                        <div>
                            <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Mission</p>
                            <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container space-y-8'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        Our people
                    </p>
                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {people.map((item, i) => (
                            <div key={i} className='space-y-2 app-animation-scale-start'>
                                <img src={item.image} alt={item.name} className='rounded-t-2xl' />
                                <p className='text-sm sm:text-base font-bold'>{item.name}</p>
                                <p className='text-xs sm:text-sm text-app-blue-3'>{item.position}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default About