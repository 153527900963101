import React from 'react'

function SendIcon({
    className = "h-4 w-4 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 1.1117C24.9589 1.20117 24.9023 1.28266 24.8328 1.35243L9.02826 17.1537L13.4918 24.5945C13.5709 24.7269 13.685 24.8348 13.8216 24.9063C13.9581 24.9778 14.1118 25.0102 14.2656 24.9998C14.4194 24.9894 14.5674 24.9367 14.6931 24.8475C14.8188 24.7582 14.9174 24.636 14.978 24.4942L25 1.1117ZM7.84801 15.9734L0.407127 11.5065C0.274793 11.4275 0.166904 11.3133 0.0953887 11.1768C0.023873 11.0402 -0.00848316 10.8865 0.00189755 10.7327C0.0122783 10.5789 0.0649915 10.431 0.154209 10.3053C0.243426 10.1795 0.365672 10.0809 0.507431 10.0204L23.895 0C23.8042 0.041272 23.7215 0.0984549 23.6509 0.168845L7.84801 15.9734Z" />
        </svg>
    )
}

export default SendIcon