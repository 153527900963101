import React from 'react'

import TextAreaInput from 'components/input/TextAreaInput'
import TextInput from 'components/input/TextInput'
import Newsletter from 'components/layout/Newsletter'

function ContactUs() {
    return (
        <div>
            <section>
                <div className='max-w-xl lg:max-w-3xl mx-auto px-4 py-4 sm:px-8 sm:py-8 space-y-6'>
                    <p className='text-xl sm:text-2xl font-bold app-animation-translate-start'>Have inquiries?<br /> Let's keep in touch.</p>
                    <p className='text-base sm:text-lg font-semibold uppercase text-app-blue-3 app-animation-translate-start'>Contact Us</p>
                    <div className='space-y-4 app-animation-translate-start'>
                        <TextInput
                            name="full_name"
                            label="Full name"
                            required
                            placeholder="Enter your full name..."
                        />
                        <TextInput
                            name="email"
                            label="Email address"
                            required
                            placeholder="Enter your email address.."
                        />
                        <TextInput
                            name="company_name"
                            label="Company name"
                            required
                            placeholder="Enter company name..."
                        />
                        <TextInput
                            name="division"
                            label="Division"
                            required
                            placeholder="Enter your division..."
                        />
                        <TextAreaInput
                            name="message"
                            label="Message"
                            required
                            placeholder="Enter your message here..."
                            rows={8}
                        />
                        <button className='bg-black text-white rounded-md px-12 py-2 text-sm sm:text-base'>Submit</button>
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default ContactUs