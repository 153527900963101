import React from 'react'

function PlanIcon({
    className = "h-8 w-8 stroke-black"
}) {
    return (
        <svg className={className} viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path strokeWidth="5" d="M46.9769 10.7039V0.484192M18.8726 46.4731H13.7627M49.5319 46.4731H23.9825M18.8726 36.2533H13.7627M49.5319 36.2533H23.9825M16.3177 10.7039V0.484192M3.54297 23.4786H59.7516M3.54297 59.2478H59.7516V10.7039H3.54297V59.2478Z" />
        </svg>
    )
}

export default PlanIcon