import React, { useEffect } from 'react'
import item1 from "assets/images/item-1.png"
import { portfolio } from 'utils/constants/data'
import Newsletter from 'components/layout/Newsletter'
import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_PUBLIC_POSTS } from 'api'


function Portfolio() {
    const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)

    useEffect(() => {
        getAllPublicPosts();
    }, [getAllPublicPosts])

    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>Leveraging real estate to the next level</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container space-y-8'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        Our portfolio
                    </p>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {publicPosts?.filter(post => post?.tag?.toLowerCase() === "portfolio").map((item, i) => (
                            <a href={`portfolio/${item?.id}`} key={i} className='flex flex-col items-center gap-2'>
                                <img className="" src={item.img_cover} alt={item.title} />
                                <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold'>
                                    {item.title}
                                </p>
                            </a>
                        ))}
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default Portfolio