import React from 'react'

function LinkedinIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.820923 1.838C0.820923 1.35053 1.01457 0.883032 1.35926 0.53834C1.70395 0.193648 2.17146 2.45031e-06 2.65892 2.45031e-06H20.9809C21.2225 -0.000392101 21.4618 0.0468654 21.6851 0.139069C21.9084 0.231273 22.1113 0.366612 22.2822 0.537339C22.4531 0.708065 22.5886 0.910826 22.6811 1.13401C22.7735 1.3572 22.8211 1.59643 22.8209 1.838V20.16C22.8212 20.4016 22.7738 20.6409 22.6815 20.8642C22.5892 21.0875 22.4537 21.2904 22.2829 21.4613C22.1121 21.6322 21.9093 21.7678 21.6861 21.8602C21.4628 21.9526 21.2235 22.0001 20.9819 22H2.65892C2.41747 22 2.17838 21.9524 1.95532 21.86C1.73226 21.7676 1.52959 21.6321 1.35891 21.4613C1.18822 21.2905 1.05285 21.0878 0.960545 20.8647C0.868236 20.6416 0.820792 20.4025 0.820923 20.161V1.838ZM9.52892 8.388H12.5079V9.884C12.9379 9.024 14.0379 8.25 15.6909 8.25C18.8599 8.25 19.6109 9.963 19.6109 13.106V18.928H16.4039V13.822C16.4039 12.032 15.9739 11.022 14.8819 11.022C13.3669 11.022 12.7369 12.111 12.7369 13.822V18.928H9.52892V8.388ZM4.02892 18.791H7.23692V8.25H4.02892V18.79V18.791ZM7.69592 4.812C7.70197 5.08668 7.6531 5.35979 7.55216 5.61532C7.45123 5.87084 7.30027 6.10364 7.10815 6.30003C6.91604 6.49643 6.68662 6.65248 6.43338 6.75901C6.18013 6.86554 5.90816 6.92042 5.63342 6.92042C5.35868 6.92042 5.08671 6.86554 4.83347 6.75901C4.58022 6.65248 4.35081 6.49643 4.15869 6.30003C3.96657 6.10364 3.81562 5.87084 3.71468 5.61532C3.61375 5.35979 3.56487 5.08668 3.57092 4.812C3.5828 4.27286 3.80531 3.75979 4.19081 3.38269C4.57631 3.00558 5.09415 2.79442 5.63342 2.79442C6.1727 2.79442 6.69054 3.00558 7.07604 3.38269C7.46154 3.75979 7.68405 4.27286 7.69592 4.812Z"
            />
        </svg>
    )
}

export default LinkedinIcon