import React from 'react'

function MultiChatBubbleIcon({
    className,
}) {
    return (
        <svg className={className} viewBox="0 0 88 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.54541 35.619C4.21208 35.619 0.666626 32.0904 0.666626 27.7777V8.17452C0.666626 3.86182 4.21208 0.333252 8.54541 0.333252H40.0606C44.3939 0.333252 47.9394 3.86182 47.9394 8.17452V27.7777C47.9394 32.0904 44.3939 35.619 40.0606 35.619H32.1818V47.3809L20.3636 35.619H8.54541ZM79.4545 59.1428C83.7878 59.1428 87.3333 55.6142 87.3333 51.3015V31.6983C87.3333 27.3856 83.7878 23.8571 79.4545 23.8571H55.8181V27.7777C55.8181 36.4031 48.7272 43.4602 40.0606 43.4602V51.3015C40.0606 55.6142 43.606 59.1428 47.9394 59.1428H55.8181V70.9047L67.6363 59.1428H79.4545Z" />
        </svg>
    )
}

export default MultiChatBubbleIcon