import React from 'react'

function ArrowRightIcon({
    className = "h-3 w-3 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.8808 10.8808C21.3673 10.3944 21.3673 9.60564 20.8808 9.11917L12.9534 1.1917C12.4669 0.705227 11.6782 0.705227 11.1917 1.1917C10.7052 1.67817 10.7052 2.46689 11.1917 2.95336L18.2383 10L11.1917 17.0466C10.7052 17.5331 10.7052 18.3218 11.1917 18.8083C11.6782 19.2948 12.4669 19.2948 12.9534 18.8083L20.8808 10.8808ZM0 11.2457H20V8.75432H0V11.2457Z" />
        </svg>
    )
}

export default ArrowRightIcon