import React from 'react'
import logo from 'assets/images/logo.png'
import TwitterIcon from 'assets/icons/social-media/TwitterIcon'
import FacebookIcon from 'assets/icons/social-media/FacebookIcon'
import InstagramIcon from 'assets/icons/social-media/InstagramIcon'
import LinkedinIcon from 'assets/icons/social-media/LinkedinIcon'
import YoutubeIcon from 'assets/icons/social-media/YoutubeIcon'

function Footer() {
    return (
        <div className='bg-app-blue text-white'>
            <div className='app-nav-container flex flex-col sm:flex-row justify-between gap-6 sm:gap-0'>
                <div className='grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-12 text-sm sm:text-base'>
                    <ul className='space-y-2'>
                        <li><a href="/about">About us</a></li>
                        <li><a href="/">Story</a></li>
                        <li><a href="/">Our people</a></li>
                    </ul>
                    <ul className='space-y-2'>
                        <li><a href="/portfolio">Our Portfolio</a></li>
                        <li><a href="/">Sustainability</a></li>
                        <li><a href="/management">Management</a></li>
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/contact-us">Contact us</a></li>
                        <li><a href="/terms-and-conditions">Terms and conditions</a></li>
                    </ul>
                </div>
                <div className='space-y-2 sm:space-y-4'>
                    <p className='font-bold text-base sm:text-lg'>Follow us</p>
                    <div className='flex gap-2 sm:gap-4'>
                        <a href='/'>
                            <TwitterIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
                        </a>
                        <a href='/'>
                            <FacebookIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
                        </a>
                        <a href='/'>
                            <InstagramIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
                        </a>
                        <a href='/'>
                            <LinkedinIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
                        </a>
                        <a href='/'>
                            <YoutubeIcon className="fill-white h-4 sm:h-6 w-4 sm:w-6" />
                        </a>
                    </div>
                </div>
            </div>
            <div className='app-nav-container flex flex-col sm:flex-row justify-end items-center sm:items-end gap-6 sm:gap-0'>
                <div className='text-[10px] sm:text-sm'>
                    <p>2023 creme and coral organics. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default Footer