import React from 'react'

function TwitterIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.8209 2.72442C28.0412 3.89722 27.0846 4.89697 25.9511 5.72368C25.9623 5.9454 25.9679 6.196 25.9679 6.47548C25.9679 8.02833 25.744 9.58306 25.2963 11.1397C24.8485 12.6963 24.1645 14.1863 23.2444 15.6096C22.3242 17.0329 21.2281 18.2938 19.9561 19.3923C18.684 20.4909 17.155 21.3668 15.369 22.0201C13.5829 22.6734 11.6688 23 9.62649 23C6.43919 23 3.504 22.1299 0.820923 20.3897C1.29714 20.4433 1.75387 20.47 2.19111 20.47C4.85384 20.47 7.23209 19.6407 9.32585 17.982C8.08434 17.959 6.97247 17.5725 5.99024 16.8226C5.00802 16.0727 4.33207 15.1144 3.96241 13.9479C4.3278 14.0182 4.68842 14.0534 5.04429 14.0534C5.55653 14.0534 6.06057 13.9859 6.5564 13.8507C5.23148 13.5824 4.13233 12.9151 3.25898 11.8488C2.38566 10.7826 1.949 9.55189 1.949 8.1568V8.08491C2.76224 8.54024 3.6297 8.78245 4.55136 8.81152C3.76648 8.2824 3.1442 7.59241 2.68449 6.74155C2.22476 5.89069 1.99489 4.9699 1.99489 3.9792C1.99489 2.93435 2.25381 1.96138 2.77165 1.06032C4.21249 2.84829 5.95836 4.2773 8.00927 5.34733C10.0602 6.41738 12.2605 7.01166 14.6101 7.13015C14.5099 6.70783 14.4597 6.26711 14.4595 5.808C14.4595 4.20496 15.0205 2.8362 16.1425 1.70172C17.2645 0.567241 18.6181 0 20.2033 0C21.863 0 23.261 0.611117 24.3973 1.83335C25.6955 1.57331 26.9111 1.10297 28.044 0.422326C27.6076 1.81137 26.7665 2.88292 25.5209 3.63699C26.6657 3.49942 27.7657 3.19523 28.8209 2.72442H28.8209Z" />
        </svg>
    )
}

export default TwitterIcon