import React from 'react'

function AvatarIcon({
    className = "fill-white h-4 w-4",
}) {
    return (
        <svg className={className} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.42212 11.2778C2.74568 11.2778 0.399902 14.6612 0.399902 16.9167V20.3001H18.4443V16.9167C18.4443 14.6612 16.0986 11.2778 9.42212 11.2778Z" />
            <path d="M9.42217 10.15C12.225 10.15 14.4972 7.87785 14.4972 5.075C14.4972 2.27215 12.225 0 9.42217 0C6.61932 0 4.34717 2.27215 4.34717 5.075C4.34717 7.87785 6.61932 10.15 9.42217 10.15Z" />
        </svg>
    )
}

export default AvatarIcon