import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import Newsletter from 'components/layout/Newsletter';
import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import useFetch from 'utils/hooks/useFetch';
import { GET_POST_DETAILS } from 'api';

function BlogDetails() {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: post, fetch: getPost } = useFetch(GET_POST_DETAILS)

    useEffect(() => {
        getPost({
            params: {
                id
            }
        });
    }, [getPost, id])

    return (
        <div>
            <section className='app-main-container'>
                <button onClick={() => navigate(-1)} className='flex gap-2 sm:gap-4 items-center'>
                    <ArrowRightIcon className='rotate-180 h-3 w-3 fill-black' />
                    <span className='text-xs sm:text-sm'>Back to previous page</span>
                </button>
            </section>
            <section className='app-main-container flex flex-col-reverse sm:flex-row gap-4 sm:gap-8'>
                <div className='space-y-4 sm:space-y-8'>
                    <h1 className='text-lg sm:text-2xl font-bold'>{post?.response?.title}</h1>
                    <p className='text-base sm:text-xl font-semibold'>{post?.response?.summary}</p>
                    <img className='w-full aspect-video object-cover' src={post?.response?.img_cover} alt={post?.response?.title} />
                    <div className='space-y-2 sm:space-y-4 text-sm sm:text-base' dangerouslySetInnerHTML={{ __html: post?.response?.content }} />
                </div>
                <div>
                    <p className='whitespace-nowrap text-xs sm:text-sm'>{moment(post?.response?.createdAt).format("DD MMMM YYYY")}</p>
                </div>
            </section>
            <Newsletter />
        </div>
    )
}

export default BlogDetails