import frameworks from 'assets/images/impact/frameworks.png'
import esgInitiatives from 'assets/images/impact/esg-initiatives.png'
import portfolioManagement from 'assets/images/impact/portfolio-management.png'

import AnalyzeIcon from "assets/icons/AnalyzeIcon"
import BuildIcon from "assets/icons/BuildIcon"
import PlanIcon from "assets/icons/PlanIcon"
import people1 from "assets/images/people/people-1.png"
import people2 from "assets/images/people/people-2.png"

import mornington from "assets/images/portfolio/mornington.png"
import wollonggong from "assets/images/portfolio/wollonggong.png"
import item1 from "assets/images/item-1.png"

export const impacts = [
    {
        image: frameworks,
        title: "Framework",
        description: "Lorem ipsum dolor sit amet consectetur.",
        href: "/"
    },
    {
        image: esgInitiatives,
        title: "ESG Initiatives",
        description: "Lorem ipsum dolor sit amet consectetur.",
        href: "/"
    },
    {
        image: portfolioManagement,
        title: "Portfolio Management",
        description: "Lorem ipsum dolor sit amet consectetur.",
        href: "/"
    },
]

export const about = {
    description: (
        <>
            <p className='app-animation-translate-start'>
                Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.
                Tempus neque pretium felis sit pellentesque imperdiet tortor.
            </p>
            <br />
            <p className='app-animation-translate-start'>
                Cursus elit sed nunc ac nisi turpis ultricies bibendum. Varius bibendum lectus quam platea nunc. Nunc odio tempor eu odio amet netus. Felis eu facilisis est gravida sapien varius viverra quam. Imperdiet sed tristique id non ut amet libero ipsum mattis. Sem pulvinar faucibus pulvinar tempus cras sodales non. Phasellus volutpat lectus tellus felis diam enim. Cursus ultrices hac id enim aliquet. Fusce integer amet maecenas luctus erat ullamcorper iaculis gravida. Molestie cursus enim consequat velit diam mi.
            </p>
        </>
    )
}

export const people = [
    {
        image: people1,
        name: "Shane Morries",
        position: "President Director",
    },
    {
        image: people2,
        name: "Phillip Tan",
        position: "Chief Operational Officer",
    },
    {
        image: people1,
        name: "Shane Morries",
        position: "President Director",
    },
    {
        image: people2,
        name: "Phillip Tan",
        position: "Chief Operational Officer",
    },
    {
        image: people1,
        name: "Shane Morries",
        position: "President Director",
    },
    {
        image: people2,
        name: "Phillip Tan",
        position: "Chief Operational Officer",
    },
]

export const portfolio = [
    {
        id: 1,
        image: mornington,
        name: "Mornington peninsula villas",
        description: (
            <>
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
            </>
        ),
        section1Photos: [
            mornington,
            mornington
        ],
        section2Photos: [
            mornington,
            mornington
        ],
        tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
    },
    {
        id: 2,
        image: wollonggong,
        name: "Wollonggong private residence",
        description: (
            <>
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
            </>
        ),
        section1Photos: [
            wollonggong,
            wollonggong
        ],
        section2Photos: [
            wollonggong,
            wollonggong
        ],
        tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
    },
    {
        id: 3,
        image: mornington,
        name: "Mornington peninsula villas",
        description: (
            <>
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
            </>
        ),
        section1Photos: [
            mornington,
            mornington
        ],
        section2Photos: [
            mornington,
            mornington
        ],
        tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
    },
    {
        id: 4,
        image: wollonggong,
        name: "Wollonggong private residence",
        description: (
            <>
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
                <br />
                <p className='app-animation-translate-start'>
                    Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
                </p>
            </>
        ),
        section1Photos: [
            wollonggong,
            wollonggong
        ],
        section2Photos: [
            wollonggong,
            wollonggong
        ],
        tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
    },
]

export const workSteps = [
    {
        icon: AnalyzeIcon,
        title: "Analyze",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper."
    },
    {
        icon: PlanIcon,
        title: "Plan",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper."
    },
    {
        icon: BuildIcon,
        title: "Build",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper."
    },
]

export const posts = [
    {
        image: mornington,
        title: "Mornington peninsula villas",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
    },
    {
        image: mornington,
        title: "Mornington peninsula villas",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
    },
    {
        image: mornington,
        title: "Mornington peninsula villas",
        description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
    },
]

export const terms = {
    updatedAt: "08 August 2023",
    content: [
        {
            title: "Modification of Content",
            body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac."
        },
        {
            title: "Data Security",
            body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac."
        },
        {
            title: "Ownership",
            body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac."
        },
        {
            title: "Material Interests",
            body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac. Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac. Lorem ipsum dolor sit amet consectetur."
        },
        {
            title: "Device Use",
            body: "Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac."
        },
    ]
}

export const blogPosts = [
    {
        id: 1,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 2,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 3,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 4,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 5,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 6,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 7,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 8,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 9,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 10,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 11,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
    {
        id: 12,
        image: item1,
        title: "Innovative design direction",
        description: "Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet lorem.",
        body: (
            <>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
                <p>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa. Mauris venenatis lacus at semper. Purus tortor massa ac ultrices gravida in. Viverra ipsum in viverra nisl dignissim gravida vitae. Scelerisque sagittis sem dignissim eleifend ac.</p>
            </>
        ),
        date: new Date(),
    },
]