import React from 'react'

function AnalyzeIcon({
    className = "h-8 w-8 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M49.9633 0.294647H6.24541C2.81043 0.294647 0 3.10508 0 6.54006V50.2579C0 53.6929 2.81043 56.5033 6.24541 56.5033H49.9633C53.3982 56.5033 56.2087 53.6929 56.2087 50.2579V6.54006C56.2087 3.10508 53.3982 0.294647 49.9633 0.294647ZM15.6135 44.0125C13.896 44.0125 12.4908 42.6073 12.4908 40.8898V31.5217C12.4908 29.8042 13.896 28.399 15.6135 28.399C17.331 28.399 18.7362 29.8042 18.7362 31.5217V40.8898C18.7362 42.6073 17.331 44.0125 15.6135 44.0125ZM28.1043 44.0125C26.3868 44.0125 24.9816 42.6073 24.9816 40.8898V37.7671C24.9816 36.0496 26.3868 34.6444 28.1043 34.6444C29.8218 34.6444 31.227 36.0496 31.227 37.7671V40.8898C31.227 42.6073 29.8218 44.0125 28.1043 44.0125ZM28.1043 28.399C26.3868 28.399 24.9816 26.9938 24.9816 25.2763C24.9816 23.5588 26.3868 22.1536 28.1043 22.1536C29.8218 22.1536 31.227 23.5588 31.227 25.2763C31.227 26.9938 29.8218 28.399 28.1043 28.399ZM40.5952 44.0125C38.8777 44.0125 37.4725 42.6073 37.4725 40.8898V15.9082C37.4725 14.1907 38.8777 12.7855 40.5952 12.7855C42.3126 12.7855 43.7179 14.1907 43.7179 15.9082V40.8898C43.7179 42.6073 42.3126 44.0125 40.5952 44.0125Z" />
        </svg>
    )
}

export default AnalyzeIcon