import React, { useEffect } from 'react';

import Newsletter from 'components/layout/Newsletter';

import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import mainImage from 'assets/images/main-image.png'
import item1 from 'assets/images/item-1.png'
import mornington from 'assets/images/portfolio/mornington.png'

import { impacts } from 'utils/constants/data';
import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_PUBLIC_POSTS } from 'api';

function Home() {
    const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)

    useEffect(() => {
        getAllPublicPosts();
    }, [getAllPublicPosts])

    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={mainImage} alt="main" className='max-h-[60vh] w-full object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl md:text-4xl max-w-md font-semibold sm:font-bold app-animation-translate-start'>We thrive in ever-changing environment with innovative solutions</p>
                            <button className='text-sm sm:text-base flex items-center gap-2 app-animation-translate-start'>
                                <p>Learn more</p>
                                <ArrowRightIcon className='h-3 sm:h-4 w-3 sm:w-4 fill-white' />
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section className='grid grid-cols-1 sm:grid-cols-2'>
                <div>
                    <img src={item1} alt="main" className='w-full h-full aspect-video object-cover object-top app-animation-translate-right-start' />
                </div>
                <div className='bg-app-blue-2 text-white px-4 py-6 md:py-12 md:px-10 lg:px-20 flex items-center justify-center sm:justify-start'>
                    <p className='text-lg sm:text-xl md:text-2xl font-semibold sm:font-bold max-w-xs text-center sm:text-left app-animation-translate-start'>The global leader in commercial real estate services and investments</p>
                </div>
            </section>

            <section className='bg-app-grey'>
                <div className='app-main-container space-y-4'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        Featured portfolio
                    </p>
                    <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {publicPosts?.filter(post => post?.tag?.toLowerCase() === "portfolio").map((item, i) => (
                            <a href={`portfolio/${item?.id}`} key={i} className='flex flex-col items-center gap-2'>
                                <img className="" src={item.img_cover} alt={item.title} />
                                <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold'>
                                    {item.title}
                                </p>
                            </a>
                        ))}
                    </div>
                </div>
            </section>

            <section className='bg-white'>
                <div className='app-main-container space-y-4'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        Our ESG and Impact
                    </p>
                    <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 py-4'>
                        {impacts.map((impact, i) => (
                            <div key={i} className='space-y-4'>
                                <img src={impact.image} alt='mornington' className='aspect-[3/4] app-animation-translate-start' />
                                <p className='font-bold text-sm sm:text-lg app-animation-translate-start'>{impact.title}</p>
                                <p className='font-semibold text-xs sm:text-base text-app-blue-3 app-animation-translate-start'>{impact.description}</p>
                                <a href={impact.href} className='flex items-center gap-2 text-xs sm:text-sm app-animation-translate-start'>
                                    <p>Learn more</p>
                                    <ArrowRightIcon className='h-2 w-2 sm:h-3 sm:w-3 fill-black' />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default Home