import React from 'react'

function FacebookIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.02058 0C2.69416 0 0.820923 1.87323 0.820923 4.19965V18.8003C0.820923 21.1267 2.69416 23 5.02058 23H12.934V14.0084H10.5564V10.7712H12.934V8.00543C12.934 5.83251 14.3389 3.8374 17.575 3.8374C18.8853 3.8374 19.8542 3.96319 19.8542 3.96319L19.778 6.98626C19.778 6.98626 18.7898 6.97692 17.7115 6.97692C16.5446 6.97692 16.3574 7.51461 16.3574 8.40724V10.7712H19.8707L19.7176 14.0084H16.3574V23H19.6213C21.9477 23 23.8209 21.1268 23.8209 18.8003V4.19968C23.8209 1.87325 21.9477 2.3e-05 19.6213 2.3e-05H5.02056L5.02058 0Z" />
        </svg>
    )
}

export default FacebookIcon