import React from 'react'

function BuildIcon({
    className = "h-8 w-8 fill-black"
}) {
    return (
        <svg className={className} viewBox="0 0 72 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.5215 42.953H50.4434V50.0311H57.5215M57.5215 28.7967H50.4434V35.8748H57.5215M64.5996 57.1092H36.2871V50.0311H43.3653V42.953H36.2871V35.8748H43.3653V28.7967H36.2871V21.7186H64.5996M29.209 14.6404H22.1309V7.56232H29.209M29.209 28.7967H22.1309V21.7186H29.209M29.209 42.953H22.1309V35.8748H29.209M29.209 57.1092H22.1309V50.0311H29.209M15.0527 14.6404H7.97461V7.56232H15.0527M15.0527 28.7967H7.97461V21.7186H15.0527M15.0527 42.953H7.97461V35.8748H15.0527M15.0527 57.1092H7.97461V50.0311H15.0527M36.2871 14.6404V0.484192H0.896484V64.1874H71.6778V14.6404H36.2871Z" />
        </svg>
    )
}

export default BuildIcon