import React, { useEffect } from 'react'
import moment from 'moment'

import item1 from "assets/images/item-1.png"
import Newsletter from 'components/layout/Newsletter'
import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_PUBLIC_POSTS } from 'api'

function News() {
    const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)

    useEffect(() => {
        getAllPublicPosts();
    }, [getAllPublicPosts])

    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>
                                Stay informed with the latest news
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='app-main-container space-y-8'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        Our News
                    </p>
                    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6  app-animation-translate-start'>
                        {publicPosts?.filter(post => post?.tag?.toLowerCase() === "news")?.map((post, i) => (
                            <a className='space-y-1 sm:space-y-2' href={`/news/${post.id}`}>
                                <img className='aspect-[4/3] object-cover' src={post.img_cover} alt={post.title} />
                                <p className='text-sm sm:text-base font-bold'>{post.title}</p>
                                <p className='text-xs sm:text-sm truncate'>{post.summary}</p>
                                <p className='text-xs sm:text-sm text-app-grey-2'>{moment(post.createdAt).format("DD MMMM YYYY")}</p>
                            </a>
                        ))}
                    </div>
                    <div className='text-center'>
                        <button className='border border-app-blue-4 text-app-blue-4 px-2 sm:px-4 py-1 sm:py-2 rounded-2xl hover:bg-app-blue-4 hover:text-white transition-all app-animation-translate-start'>Load More</button>
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default News