import React from 'react'

import item1 from "assets/images/item-1.png"
import { terms } from 'utils/constants/data'

function Terms() {
    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8'></div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container space-y-8'>
                    <div className='space-y-2'>
                        <p className='text-lg sm:text-3xl font-bold'>
                            Terms and conditions
                        </p>
                        <p className='text-xs sm:text-sm'>
                            Updated on {terms?.updatedAt}
                        </p>
                    </div>
                    <div className='space-y-4'>
                        {terms?.content?.map((term, i) => (
                            <div className='space-y-2'>
                                <p className='text-sm sm:text-base font-bold uppercase'>{term.title}</p>
                                <p className='text-sm sm:text-base font-medium'>{term.body}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Terms