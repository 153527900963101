import React from 'react'
import item1 from "assets/images/item-1.png"
import { posts, workSteps } from 'utils/constants/data'
import ArrowRightIcon from 'assets/icons/ArrowRightIcon'
import visionMission from "assets/images/vision-mission.png"
import Newsletter from 'components/layout/Newsletter'

function Management() {
    return (
        <div>
            <section>
                <div className='relative'>
                    <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>Leveraging real estate to the next level</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container grid grid-cols-1 sm:grid-cols-3 gap-8'>
                    {workSteps.map((step, i) => {
                        const Icon = step.icon
                        return (
                            <div key={i} className='space-y-4'>
                                <div className='space-y-2 app-animation-translate-start'>
                                    <Icon />
                                    <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
                                </div>
                                <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
                            </div>
                        )
                    })}
                </div>
            </section>

            <section>
                <div className='app-main-container flex flex-col-reverse sm:flex-row items-center gap-4 md:gap-12'>
                    <div className='flex flex-col gap-2 flex-1'>
                        <p className='text-app-blue-3 font-semibold text-base sm:text-lg app-animation-translate-start'>Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. Sed faucibus sagittis a sed commodo fermentum massa.</p>
                        <button className='flex items-center gap-2 text-sm sm:text-base app-animation-scale-start'>
                            <p>Learn more</p>
                            <ArrowRightIcon className='h-3 w-3 fill-black' />
                        </button>
                    </div>
                    <div className='flex-1'>
                        <img className='app-animation-translate-left-start' src={visionMission} alt="vision and mission" />
                    </div>
                </div>
            </section>

            <section className='bg-app-grey'>
                <div className='app-main-container space-y-4'>
                    <p className='text-lg sm:text-xl font-bold app-animation-translate-start'>Related posts</p>
                    <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8'>
                        {posts.map((post, i) => {
                            return (
                                <div key={i} className='space-y-2 text-center text-sm sm:text-base app-animation-scale-start'>
                                    <img src={post.image} alt={post.title} />
                                    <p className='font-semibold text-app-blue-3 text-sm md:text-base'>{post.title}</p>
                                    <p className='text-sm md:text-base'>{post.description}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <Newsletter />
        </div>
    )
}

export default Management