import { useCallback, useState } from 'react'
import api from 'api'

function useFetch(request, disable) {
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(disable ? false : true)
    const [error, setError] = useState(null)

    const fetch = useCallback(async ({ query, params, data, headers } = {}) => {
        if (!disable) {
            try {
                setLoading(true)
                const paramKeys = params ? Object.keys(params) : [];
                const response = await api.request({
                    method: request.method,
                    url: params ? request.url.replace(`:${paramKeys[0]}`, params[paramKeys[0]]) : request.url,
                    params: query || undefined,
                    data: data || undefined,
                    headers: headers || undefined,
                })
                setData(response.data)
                return response.data
            } catch (error) {
                setError(error.response)
            } finally {
                setLoading(false)
            }
        }
    }, [request, disable])

    return {
        data,
        loading,
        error,
        fetch,
    }
}

export default useFetch