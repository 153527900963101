import React from 'react'

function YoutubeIcon({
    className = "fill-white h-4 w-4"
}) {
    return (
        <svg className={className} viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.1739 3.43662C30.9975 2.77115 30.6515 2.16428 30.1706 1.67675C29.6897 1.18922 29.0908 0.83812 28.4338 0.658599C26.0153 1.33636e-07 16.3209 0 16.3209 0C16.3209 0 6.6265 -1.33636e-07 4.20809 0.655095C3.55079 0.834035 2.95157 1.18494 2.47059 1.67256C1.98961 2.16018 1.6438 2.76736 1.46791 3.43312C0.820923 5.88535 0.820923 11 0.820923 11C0.820923 11 0.820923 16.1147 1.46791 18.5634C1.82427 19.9156 2.87606 20.9806 4.20809 21.3414C6.6265 22 16.3209 22 16.3209 22C16.3209 22 26.0153 22 28.4338 21.3414C29.7692 20.9806 30.8176 19.9156 31.1739 18.5634C31.8209 16.1147 31.8209 11 31.8209 11C31.8209 11 31.8209 5.88535 31.1739 3.43662ZM13.2417 15.6943V6.30573L21.2685 10.965L13.2417 15.6943Z" />
        </svg>
    )
}

export default YoutubeIcon