import React, { useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Menu from 'components/menu/Menu'
import SupportChat from 'components/support_chat/SupportChat'
import useAnimation from 'utils/hooks/useAnimation'
import CookieConsent from 'components/modal/cookie_consent/CookieConsent'
import Cookies from 'js-cookie'

function MainContainer({ children }) {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isOpenCookieConsent, setOpenCookieConsent] = useState(true);

    useAnimation()

    return (
        <div>
            <Navbar
                openMenu={() => setMenuOpen(true)}
            />
            {children}
            <Footer />
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setMenuOpen(false)}
            />
            <SupportChat />
            {isOpenCookieConsent && window.location.pathname !== "/privacy-policy" && Cookies.get('cookie_consent') !== "true" && (
                <CookieConsent setOpenCookieConsent={setOpenCookieConsent} />
            )}
        </div>
    )
}

export default MainContainer