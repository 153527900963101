import React from 'react'

function TextAreaInput({
    name,
    label,
    placeholder,
    rows,
    disabled,
    value,
    onChange,
    onBlur,
    error,
}) {
    return (
        <div className='flex flex-col space-y-2'>
            <label for={name} className='font-bold flex text-sm sm:text-base'>
                <p>{label}</p>
            </label>
            <textarea
                type="text"
                id={name}
                placeholder={placeholder}
                rows={rows}
                className='border border-app-light-grey rounded-lg bg-transparent px-3 py-2 text-sm sm:text-base'
                disabled={disabled}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            {error && (
                <p className='text-xs text-red-500'>{error}</p>
            )}
        </div>
    )
}

export default TextAreaInput