import axios from 'axios'
import { API_BASE_URL } from 'utils/constants/api'

const baseURL = API_BASE_URL

const api = axios.create({
    baseURL,
    withCredentials: true
})

export default api;

export const GET_ALL_PUBLIC_POSTS = {
    method: 'get',
    url: "/api/blog/v1/blogs/public"
}

export const GET_POST_DETAILS = {
    method: 'get',
    url: "/api/blog/v1/blogs/:id"
}